import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { listRewards } from "../../../../graphql/queries/listRewards";
import RewardGroupOutcomes from "./RewardGroupOutcomes";
import RewardOutcome from "./RewardOutcome";
import SummaryCard from "./SummaryCard";

const CampaignOutcomes = ({
  isViewScreen,
  handleOutcomesEditSection,
  campaignStateData,
}) => {
  const [outcomes, setOutcomes] = useState([]);
  const listRewardsData = useQuery(listRewards);
  useEffect(() => {
    if (
      campaignStateData?.outcomes?.length &&
      campaignStateData?.outcomes?.[0]?.outcomeDefinition?.slug?.indexOf("journey") >
        -1 &&
      campaignStateData?.outcomes?.[0]?.outcomeData?.reward_group_ids?.length
    ) {
      setOutcomes(
        campaignStateData?.outcomes?.[0]?.outcomeData?.reward_group_ids?.map(
          (id) => ({
            outcomeData: { reward_group_id: id },
            outcomeDefinition: campaignStateData?.outcomes?.[0]?.outcomeDefinition,
            outcomeRules: [],
          })
        )
      );
    } else {
      setOutcomes(campaignStateData.outcomes);
    }
  }, [campaignStateData.outcomes]);

  return (
    <SummaryCard
      title="Campaign Setup - Outcomes"
      isViewScreen={isViewScreen}
      handleEditSection={handleOutcomesEditSection}
    >
      {outcomes.length > 0 &&
        outcomes.map((outcome, index) =>
          outcome?.outcomeDefinition?.slug === "direct-rewards" ? (
            <RewardOutcome outcome={outcome} key={outcome?.outcomeDefinition.id} />
          ) : (
            <RewardGroupOutcomes
              outcome={outcome}
              key={outcome?.outcomeData?.reward_group_id || index}
              campaignState={campaignStateData}
              listRewards={listRewardsData?.data?.listRewards?.items}
            />
          )
        )}
    </SummaryCard>
  );
};

export default CampaignOutcomes;
