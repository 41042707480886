import { lazy } from "react";

import { Navigate } from "react-router-dom";
import Loadable from "../components/common/Loadable";
import SegmentDataTab from "../components/segmentation/SegmentsDataTab";
import SegmentsListTab from "../components/segmentation/SegmentsListTab";
import { BannerProvider } from "../context/BannerContext";
import { CampaignSetupProvider } from "../context/CampaignSetupContext";
import { EditWidgetProvider } from "../context/EditWidgetContext";
import { SurveyProvider } from "../context/SurveyContext";
import Layout from "../layout/Layout";
import { NotFoundPage } from "../pages/NotFoundPage";
import ActivitiesListOutlet from "../pages/campaigns/setupPages/ActivitiesListOutlet";
import CodeGroupsListPage from "../pages/codeGroups/CodeGroupsListPage";
import ContentOutlet from "../pages/content/ContentOutlet";
import ContentDetailsPage from "../pages/content/contentDetails/ContentDetailsPage";
import AppConfigurationsEditPage from "../pages/management/AppConfigurationsEditPage";
import AppConfigurationsPage from "../pages/management/AppConfigurationsPage";
import ManagementOutlet from "../pages/management/ManagementOutlet";
import SocialMediaLinksEditPage from "../pages/management/SocialMediaLinks/SocialMediaLinksEditPage";
import SocialMediaLinksPage from "../pages/management/SocialMediaLinks/SocialMediaLinksPage";
import SystemSettingPage from "../pages/management/SystemSettingPage";
import TermAndConditionEditPage from "../pages/management/TermAndConditionEditPage";
import TermAndConditionPage from "../pages/management/TermAndConditionPage";
import TermAndConditionViewPage from "../pages/management/TermAndConditionViewPage";
import UserLimitSettingsEditPage from "../pages/management/UserLimitSettingsEditPage";
import UserLimitSettingsPage from "../pages/management/UserLimitSettingsPage";
import MemberOutlet from "../pages/member/MemberOutlet";
import SegmentListPage from "../pages/organizations/Segmentation/SegmentListPage";
import SegmentSummaryViewPage from "../pages/organizations/Segmentation/SegmentSummaryView/SegmentSummaryViewPage";
import CodeGroupsOutlet from "../pages/partners/CodeGroupsOutlet";
import PartnersPage from "../pages/partners/PartnersPage";
import SurveyListPage from "../pages/survey/SurveyListPage";
import SurveyOutlet from "../pages/survey/SurveyOutlet";
import SweepstakesOutlet from "../pages/sweepstakes/SweepstakesOutlet";
import WidgetsOutlet from "../pages/widgets/WidgetsOutlet";
import WinningRewardsScreen from "../pages/winningRewardsScreen/WinningRewardsScreen";
import { paths } from "./paths";

// dashboard
const DashboardPage = Loadable(lazy(() => import("../pages/dashboard")));

// campaign
const CampaignsPage = Loadable(
  lazy(() => import("../pages/campaigns/CampaignsPage"))
);
const CampaignListPage = Loadable(
  lazy(() => import("../pages/campaigns/CampaignListPage"))
);
const CampaignSummaryViewPage = Loadable(
  lazy(() => import("../pages/campaigns/CampaignSummaryViewPage"))
);

const CampaignCalendarPage = Loadable(
  lazy(() => import("../pages/campaigns/CampaignCalendarPage"))
);
const GenericSetupPage = Loadable(
  lazy(() => import("../pages/campaigns/setupPages/GenericSetupPage"))
);
const CampaignSetupOutletPage = Loadable(
  lazy(() => import("../pages/campaigns/setupPages"))
);
const SegmentSetupPage = Loadable(
  lazy(() => import("../pages/campaigns/setupPages/SegmentSetupPage"))
);
const ActivitiesSetupPage = Loadable(
  lazy(() => import("../pages/campaigns/setupPages/ActivitiesSetupPage"))
);
const ActivitySetupPage = Loadable(
  lazy(() => import("../pages/campaigns/setupPages/ActivitySetupPage"))
);
const ActivitiesListPage = Loadable(
  lazy(() => import("../pages/campaigns/setupPages/ActivitiesListPage"))
);
const CampaignLevelSetupPage = Loadable(
  lazy(() => import("../pages/campaigns/setupPages/CampaignLevelSetupPage"))
);
const SummaryPage = Loadable(
  lazy(() => import("../pages/campaigns/setupPages/SummaryPage"))
);

const RewardsPage = Loadable(lazy(() => import("../pages/rewards/RewardsPage")));
const ProductsPage = Loadable(lazy(() => import("../pages/products/ProductsPage")));

const BannersListPage = Loadable(
  lazy(() => import("../pages/banners/BannersListPage"))
);

const WidgetListPage = Loadable(
  lazy(() => import("../pages/widgets/widgetList/WidgetListPage"))
);

const WidgetDetailPage = Loadable(
  lazy(() => import("../components/management/WidgetPages/WidgetDetailPage"))
);

const MemberHistoryPage = Loadable(
  lazy(
    () => import("../pages/widgets/widgetCreate/MemberHistoryPage/MemberHistoryPage")
  )
);

const MemberInformationPage = Loadable(
  lazy(
    () =>
      import(
        "../pages/widgets/widgetCreate/MemberInformationPage/MemberInformationPage"
      )
  )
);
const ListOfSweepstakesPage = Loadable(
  lazy(
    () =>
      import(
        "../pages/widgets/widgetCreate/ListOfSweepstakesPage/ListOfSweepstakesPage"
      )
  )
);
const ListOfCampaignsPage = Loadable(
  lazy(
    () =>
      import("../pages/widgets/widgetCreate/ListOfCampaignsPage/ListOfCampaignsPage")
  )
);

const SweepstakesEntrantsWinnersPage = Loadable(
  lazy(
    () =>
      import(
        "../pages/sweepstakes/sweepstakesEntrantsWinners/SweepstakesEntrantsWinnersPage"
      )
  )
);
const SweepstakesPickWinnersPage = Loadable(
  lazy(
    () =>
      import(
        "../pages/sweepstakes/sweepstakesPickWinners/SweepstakesPickWinnersPage"
      )
  )
);
const ArchivesListPage = Loadable(
  lazy(() => import("../pages/sweepstakes/archives/ArchivesListPage"))
);

//Member relationship pages

const MemberRelationListPage = Loadable(
  lazy(() => import("../pages/member/MemberListPage"))
);
const MemberRelationDetailPage = Loadable(
  lazy(() => import("../pages/member/MemberRelationDetailPage"))
);

// Content Pages

const ContentListPage = Loadable(
  lazy(() => import("../pages/content/contentList/ContentListPage"))
);

const ContentCreationPage = Loadable(
  lazy(() => import("../pages/content/ContentCreation/ContentCreationPage"))
);

//Segmentation Pages
const SegmentOutlet = Loadable(
  lazy(() => import("../pages/organizations/SegmentOutlet"))
);

const widgetsRoute = {
  path: paths.widgets,
  element: <WidgetsOutlet />,
  children: [
    {
      path: `${paths.widget_list}`,
      element: <WidgetListPage />,
    },
    {
      path: paths.create_member_history,
      element: <MemberHistoryPage />,
    },
    {
      path: `${paths.edit_member_history}/:widgetId`,
      element: <MemberHistoryPage />,
    },
    {
      path: paths.create_member_information,
      element: <MemberInformationPage />,
    },
    {
      path: `${paths.edit_member_information}/:widgetId`,
      element: <MemberInformationPage />,
    },
    {
      path: paths.create_list_of_sweepstakes,
      element: <ListOfSweepstakesPage />,
    },
    {
      path: `${paths.edit_list_of_sweepstakes}/:widgetId`,
      element: <ListOfSweepstakesPage />,
    },
    {
      path: paths.create_list_of_campaigns,
      element: <ListOfCampaignsPage />,
    },
    {
      path: `${paths.edit_list_of_campaigns}/:widgetId`,
      element: <ListOfCampaignsPage />,
    },
    {
      path: "",
      element: <Navigate to={`/${paths.widgets}/${paths.widget_list}`} />,
    },
    {
      path: paths.any,
      element: <Navigate to={`/${paths.widgets}/${paths.widget_list}`} />,
    },
  ],
};

const managementRoute = {
  path: paths.system_setting,
  element: <ManagementOutlet />,
  children: [
    {
      index: true,
      element: (
        <EditWidgetProvider>
          <SystemSettingPage />
        </EditWidgetProvider>
      ),
    },
    {
      path: `${paths.term_and_condition}`,
      element: <TermAndConditionPage />,
    },
    {
      path: `${paths.term_and_condition_view}/:id`,
      element: <TermAndConditionViewPage />,
    },
    {
      path: `${paths.term_and_condition_edit}/:id`,
      element: <TermAndConditionEditPage />,
    },
    {
      path: `${paths.user_login_limits}`,
      element: <UserLimitSettingsPage />,
    },
    {
      path: `${paths.user_login_limits}/edit`,
      element: <UserLimitSettingsEditPage />,
    },
    {
      path: `${paths.social_media_links}`,
      element: <SocialMediaLinksPage />,
    },
    {
      path: `${paths.social_media_links}/edit`,
      element: <SocialMediaLinksEditPage />,
    },
    {
      path: `${paths.widgets_configuration}`,
      element: <WidgetDetailPage />,
    },
    {
      path: `${paths.widgets_configuration}/edit`,
      element: <div>Widgets Configuration Edit</div>,
    },
    {
      path: `${paths.app_configurations}`,
      element: <AppConfigurationsPage />,
    },
    {
      path: `${paths.app_configurations}/edit`,
      element: <AppConfigurationsEditPage />,
    },
  ],
};

const memberRoute = {
  path: paths.member_list,
  element: <MemberOutlet />,
  children: [
    {
      index: true,
      element: <MemberRelationListPage />,
    },
    {
      path: `${paths.member}/:memberId`,
      element: <MemberRelationDetailPage />,
    },
  ],
};

const campaignsRoute = {
  path: paths.campaigns,
  element: (
    <CampaignSetupProvider>
      <CampaignsPage />
    </CampaignSetupProvider>
  ),
  children: [
    {
      path: paths.campaign_list,
      element: <CampaignListPage />,
    },
    {
      path: `${paths.campaign_summary_view}/:id`,
      element: <CampaignSummaryViewPage />,
    },
    {
      path: paths.campaign_calendar,
      element: <CampaignCalendarPage />,
    },
    {
      path: paths.create,

      element: <CampaignSetupOutletPage />,
      children: [
        {
          path: paths.generic_setup,
          element: <GenericSetupPage />,
        },
        {
          path: paths.segment_specific_setup,
          element: <SegmentSetupPage />,
        },
        {
          path: paths.acitivity_level_setup,
          element: <ActivitiesSetupPage />,
        },
        {
          path: paths.acitivity_level_setup_new,
          element: <ActivitiesListOutlet />,
          children: [
            {
              index: true,
              element: <ActivitiesListPage />,
            },
            {
              path: `${paths.acitivity_level_setup_new}/:activityIndex`,
              element: <ActivitySetupPage />,
            },
          ],
        },
        {
          path: paths.campaign_level_setup,
          element: <CampaignLevelSetupPage />,
        },
        {
          path: paths.summary,
          element: <SummaryPage />,
        },
        {
          path: "",
          element: (
            <Navigate
              to={`/${paths.campaigns}/${paths.create}/${paths.generic_setup}`}
            />
          ),
        },
        {
          path: paths.any,
          element: (
            <Navigate
              to={`/${paths.campaigns}/${paths.create}/${paths.generic_setup}`}
            />
          ),
        },
      ],
    },
    {
      path: `${paths.edit}/:campaignId`,

      element: <CampaignSetupOutletPage />,
      children: [
        {
          path: paths.generic_setup,
          element: <GenericSetupPage />,
        },
        {
          path: paths.segment_specific_setup,
          element: <SegmentSetupPage />,
        },
        {
          path: paths.acitivity_level_setup,
          element: <ActivitiesSetupPage />,
        },
        {
          path: paths.acitivity_level_setup_new,
          element: <ActivitiesListOutlet />,
          children: [
            {
              index: true,
              element: <ActivitiesListPage />,
            },
            {
              path: `${paths.acitivity_level_setup_new}/:activityIndex`,
              element: <ActivitySetupPage />,
            },
          ],
        },
        {
          path: paths.campaign_level_setup,
          element: <CampaignLevelSetupPage />,
        },
        {
          path: paths.summary,
          element: <SummaryPage />,
        },
        {
          path: "",
          element: (
            <Navigate
              to={`/${paths.campaigns}/${paths.create}/${paths.generic_setup}`}
            />
          ),
        },
        {
          path: paths.any,
          element: (
            <Navigate
              to={`/${paths.campaigns}/${paths.create}/${paths.generic_setup}`}
            />
          ),
        },
      ],
    },
    {
      path: "",
      element: <Navigate to={`/${paths.campaigns}/${paths.campaign_list}`} />,
    },
    {
      path: paths.any,
      element: <Navigate to={`/${paths.campaigns}/${paths.campaign_list}`} />,
    },
  ],
};

const partnersRoute = {
  path: paths.partners,
  element: <CodeGroupsOutlet />,
  children: [
    {
      index: true,
      element: <PartnersPage />,
    },
    // {
    //   path: paths.create,
    //   element: <CreateCodeGroupsPage />,
    // },
  ],
};

const segmentationRoute = {
  path: paths.segmentation,
  element: <SegmentOutlet />,
  children: [
    {
      path: paths.segment_list,
      element: <SegmentListPage />,
      children: [
        {
          index: true,
          element: <SegmentDataTab />,
        },
        {
          path: "list",
          element: <SegmentsListTab />,
        },
      ],
    },
    {
      path: `${paths.segment_summary_view}/:segmentId`,
      element: <SegmentSummaryViewPage />,
    },
  ],
};

const rewardsRoute = {
  path: paths.rewards,
  element: <RewardsPage />,
  children: [
    {
      index: true,
      element: <RewardsPage />,
    },
  ],
};

const codeGroupsRoute = {
  path: paths.code_groups,
  element: <CodeGroupsOutlet />,
  children: [
    {
      index: true,
      element: <CodeGroupsListPage />,
    },
  ],
};
const productsRoute = {
  path: paths.products,
  element: <ProductsPage />,
  children: [
    {
      index: true,
      element: <ProductsPage />,
    },
  ],
};

const winningRewardsScreenRoute = {
  path: paths.winning_rewards_screen,
  element: <WinningRewardsScreen />,
  children: [
    {
      index: true,
      element: <WinningRewardsScreen />,
    },
  ],
};

const contentTypes = ["image", "video", "text"];
const contentTypeRoutes = contentTypes.map((type) => ({
  path: `${paths.create}/${type}`,
  element: <ContentCreationPage contentType={type} />,
}));

const contentRoute = {
  path: paths.content,
  element: <SurveyOutlet />,
  children: [
    {
      path: paths.content_pages,
      element: <ContentOutlet />,
      children: [
        {
          index: true,
          element: <ContentListPage />,
        },
        ...contentTypeRoutes,
        {
          path: `${paths.edit}/:contentId`,
          element: <ContentCreationPage edit={true} />,
        },
        {
          path: `${paths.details}/:contentId`,
          element: <ContentDetailsPage />,
        },
      ],
    },
    {
      path: paths.banners,
      element: (
        <BannerProvider>
          <BannersListPage />
        </BannerProvider>
      ),
    },
    {
      path: paths.survey_list,
      element: (
        <SurveyProvider>
          <SurveyListPage />
        </SurveyProvider>
      ),
    },
    {
      path: paths.create,
      element: <Navigate to={`/${paths.content}/${paths.content_list}`} />,
    },
    {
      path: "",
      element: <Navigate to={`/${paths.content}/${paths.survey_list}`} />,
    },
    {
      path: paths.any,
      element: <Navigate to={`/${paths.content}/${paths.survey_list}`} />,
    },
    {
      path: "",
      element: <Navigate to={`/${paths.content}/${paths.banners}`} />,
    },
    {
      path: paths.any,
      element: <Navigate to={`/${paths.content}/${paths.banners}`} />,
    },
  ],
};

const sweepstakesRoute = {
  path: paths.sweepstakes,
  element: <SweepstakesOutlet />,
  children: [
    {
      index: true,
      element: <Navigate to={paths.entrants_winners} />,
    },
    {
      path: paths.entrants_winners,
      element: <SweepstakesEntrantsWinnersPage />,
    },
    {
      path: `${paths.entrants_winners}/${paths.pick_winners}/:sweepstakesId`,
      element: <SweepstakesPickWinnersPage />,
    },
    {
      path: paths.sweepstakes_archiving,
      element: <ArchivesListPage />,
    },
    {
      path: paths.any,
      element: <Navigate to={paths.entrants_winners} />,
    },
  ],
};

export const config = [
  {
    path: paths.default,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        ...campaignsRoute,
      },
      {
        ...contentRoute,
      },
      {
        ...partnersRoute,
      },
      {
        ...rewardsRoute,
      },
      {
        ...productsRoute,
      },
      {
        ...winningRewardsScreenRoute,
      },
      {
        ...widgetsRoute,
      },
      {
        ...memberRoute,
      },
      {
        ...managementRoute,
      },
      {
        ...sweepstakesRoute,
      },
      {
        ...segmentationRoute,
      },
      {
        ...codeGroupsRoute,
      },
      { path: paths.any, element: <NotFoundPage /> }, //
    ],
  },
];
